/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useState } from 'react';

type ListMenuTypes = 'Dashboard' | 'Transaction' | 'Budget' | 'Goals' | 'Wallet';

interface NavbarItemInterface {
  name: ListMenuTypes;
  href: string;
}
const navigationInit: Array<NavbarItemInterface> = [
  { name: 'Dashboard', href: '#' },
  { name: 'Transaction', href: '/transactions' },
  { name: 'Budget', href: '#' },
  { name: 'Goals', href: '#' },
  { name: 'Wallet', href: '#' }
];

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
  const [activeMenu, setActiveMenu] = useState<ListMenuTypes>('Dashboard');

  const onClickNavMenu = (item: NavbarItemInterface) => {
    setActiveMenu(item.name);
  };

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigationInit.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={() => onClickNavMenu(item)}
                        className={classNames(
                          item.name === activeMenu
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.name === activeMenu ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigationInit.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  onClick={() => onClickNavMenu(item)}
                  className={classNames(
                    item.name === activeMenu
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.name === activeMenu ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
