import 'tailwindcss/tailwind.css';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { AppTrackingInterface } from '@interface/TrackingInterface';
import AppLayout from '@layout/AppLayout';
import { AppProps } from 'next/dist/shared/lib/router/router';
import Head from 'next/head';
import { track } from 'react-tracking';
import { createGlobalStyle } from 'styled-components';
import { Provider } from 'urql';

import { graphQLClient } from '../GraphQLClient';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: #f3f3f3;
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  #__next {
    height: 100%;
  }
`;

const MyApp = ({ Component, pageProps, err }: AppProps) => {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        <title>NextJS Starter</title>
        <meta name="description" content="NextJS Starter" />
        <meta name="keywords" content="NextJS Starter" />

        <link rel="apple-touch-icon" sizes="57x57" href="/icons/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/icons/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/icons/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/icons/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/icons/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/icons/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/icons/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/icons/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/icons/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />

        <link rel="manifest" href="/manifest.json" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/icons/ms-icon-144x144.png" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <GlobalStyle />
      <Provider value={graphQLClient}>
        <ChakraProvider
          theme={extendTheme({
            config: {
              cssVarPrefix: 'ck',
              initialColorMode: 'dark'
            }
          })}
        >
          <AppLayout>
            <Component {...pageProps} err={err} />
          </AppLayout>
        </ChakraProvider>
      </Provider>
    </>
  );
};

const TrackedApp = track(
  {},
  {
    dispatch: (data: AppTrackingInterface) => {
      const trackProperties = {};

      if (data.properties) {
        Object.assign(trackProperties, data.properties);
      }

      // TODO: send tracker data to server
    }
  }
)(MyApp);

export default TrackedApp;
