import Navbar from '@components/Navbar';
import React from 'react';
import styled from 'styled-components';

const LayoutStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;

const MainStyled = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

const ContentContainerStyled = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
`;

export default function AppLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <LayoutStyled>
        <Navbar />
        <MainStyled>
          <ContentContainerStyled>{children}</ContentContainerStyled>
        </MainStyled>
      </LayoutStyled>
    </>
  );
}
