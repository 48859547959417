import { createClient } from 'urql';

export const graphQLClient = createClient({
  url: 'http://localhost:8181/v1beta1/relay',
  fetchOptions: () => {
    return {
      headers: {
        'x-hasura-user-id': '35d2eb77-fe88-427d-ac15-5062e6085475'
      }
    };
  }
});
